<template>
  <b-button type="is-primary is-light" @click="cancelEdit">Cancel</b-button>
</template>

<script>
export default {
  methods: {
    cancelEdit() {
      // navigate back to previous page
      this.$router.go(-1);
    },
  },
};
</script>
