<template>
  <div class="container is-max-desktop mb-4">
    <employer-form></employer-form>
  </div>
</template>

<script>
import EmployerForm from "../../components/forms/EmployerEdit.vue";

export default {
  components: {
    EmployerForm,
  },
  computed: {
    getEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
}

.side-bar {
  position: relative;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 5rem;
}

.red {
  border: 1px solid red;
}

.blue {
  border: 1px solid blue;
}
</style>
