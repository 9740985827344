<template>
  <!-- Tags -->
  <div class="box" style="min-height: 25vh" v-show="!isLoading">
    <div class="columns">
      <div class="column is-2">
        <figure class="image is-128x128" style="margin: 0 auto">
          <img class="img-profile" :src="employer.logo" />
        </figure>
      </div>
      <div class="column">
        <div class="flex" style="display: flex; flex-direction: column">
          <h1
            class="cover-title mb-1"
            style="font-size: 1.5rem; font-weight: 600"
          >
            {{ employer.name }}
          </h1>
        </div>

        <p class="article-sub-heading mb-2">
          iulaanu.com/employers/
          <strong> {{ employer.slug }}</strong>
        </p>
      </div>
    </div>
    <hr />
    <div class="heading mb-4">Branding</div>
    <div class="columns mb-4">
      <div class="column">
        <b-field
          label="Logo"
          :message="this.fields.logo.message"
          :type="this.fields.logo.class"
        >
          <b-field class="file is-primary" :class="{ 'has-name': !!file }">
            <b-upload
              v-model="file"
              class="file-label"
              accept=".jpeg,.jpg,.png,"
              rounded
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">{{
                  file.name || "Click to upload"
                }}</span>
              </span>
            </b-upload>
          </b-field>
        </b-field>
      </div>
    </div>

    <hr />
    <!-- General information -->
    <div class="heading mb-4">General</div>
    <div class="columns mb-4">
      <div class="column is-6">
        <div class="field">
          <label class="label">{{ fields.name.label }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="employer.name"
              :class="`input ` + fields.name.class"
              placeholder="Employer Name"
              :readonly="true"
            />
          </div>
          <p class="help">{{ fields.name.message }}</p>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <label class="label">{{ fields.reg_no.label }}</label>
          <div class="control">
            <input
              :class="`input ` + fields.reg_no.class"
              type="text"
              v-model="employer.reg_no"
              placeholder="Business Registation No"
              :readonly="readOnly"
              @blur="validateField('reg_no')"
            />
          </div>
          <p class="help is-danger">{{ fields.reg_no.message }}</p>
        </div>
      </div>
    </div>
    <div class="columns mb-4">
      <div class="column">
        <b-field
          :label="fields.intro.label"
          :message="fields.intro.message"
          :type="fields.intro.class"
        >
          <b-input
            maxlength="300"
            type="textarea"
            placeholder="A small introduction about the employer"
            v-model="employer.intro"
            @blur="validateField('intro')"
          ></b-input>
        </b-field>
      </div>
    </div>

    <hr />
    <!-- Contact details -->
    <div class="heading mb-4">Contact Details</div>
    <div class="columns mb-4">
      <div class="column is-3">
        <div class="field">
          <label class="label">{{ fields.phone.label }}</label>
          <div class="control">
            <input
              :class="`input ` + fields.phone.class"
              type="text"
              v-model="employer.phone"
              placeholder="Contact number"
              :readonly="readOnly"
              @blur="validateField('phone')"
            />
          </div>
          <p class="help is-danger">{{ fields.phone.message }}</p>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{ fields.email.label }}</label>
          <div class="control">
            <input
              :class="`input ` + fields.email.class"
              type="text"
              v-model="employer.email"
              placeholder="Email"
              :readonly="readOnly"
              @blur="validateField('email')"
            />
          </div>
          <p class="help is-danger">{{ fields.email.message }}</p>
        </div>
      </div>
    </div>

    <hr />
    <!-- Address information -->
    <div class="heading mb-4">Address</div>
    <div class="columns mb-4">
      <div class="column is-3">
        <div class="field">
          <label class="label">{{ fields.location.label }}</label>
          <div class="control">
            <div :class="`select is-fullwidth ` + fields.location.class">
              <select
                v-model="employer.location"
                @blur="validateField('location')"
              >
                <option
                  v-for="loc in locationOptions"
                  :key="loc.value"
                  :value="loc.value"
                >
                  {{ loc.text }}
                </option>
              </select>
            </div>
          </div>
          <p class="help is-danger">{{ fields.location.message }}</p>
        </div>
      </div>
      <div class="column is-half">
        <div class="field">
          <label class="label">{{ fields.address.label }}</label>
          <div class="control">
            <input
              :class="`input ` + fields.address.class"
              type="text"
              v-model="employer.address"
              placeholder="Employer address"
              :readonly="readOnly"
              @blur="validateField('address')"
            />
          </div>
          <p class="help is-danger">{{ fields.address.message }}</p>
        </div>
      </div>
    </div>

    <hr />
    <!-- Helper information -->
    <div class="heading mb-4">Helper Information</div>
    <div class="columns mb-4">
      <div class="column is-3">
        <div class="field">
          <label class="label">{{ fields.org_size.label }}</label>
          <div class="control">
            <div :class="`select is-fullwidth ` + fields.org_size.class">
              <select
                v-model="employer.org_size"
                @blur="validateField('org_size')"
                @change="validateField('org_size')"
              >
                <option
                  v-for="orgsize in orgSizeOptions"
                  :key="orgsize.value"
                  :value="orgsize.value"
                >
                  {{ orgsize.text }}
                </option>
              </select>
            </div>
          </div>
          <p class="help is-danger">{{ fields.org_size.message }}</p>
        </div>
      </div>
      <!-- <div class="column is-3">
        <div class="field">
          <label class="label">{{ fields.sector.label }}</label>
          <div class="control">
            <div :class="`select is-fullwidth ` + fields.sector.class">
              <select
                v-model="employer.sector"
                @blur="validateField('sector')"
                @change="validateField('sector')"
              >
                <option
                  v-for="sector in sectorOptions"
                  :key="sector.value"
                  :value="sector.value"
                >
                  {{ sector.text }}
                </option>
              </select>
            </div>
          </div>
          <p class="help is-danger">{{ fields.sector.message }}</p>
        </div>
      </div> -->
      <div class="column is-6">
        <div class="field">
          <label class="label">{{ fields.website.label }}</label>
          <div class="control">
            <input
              type="text"
              :class="`input ` + fields.website.class"
              v-model="employer.website"
              placeholder="eg. www.employer.com"
              :readonly="readOnly"
              @blur="validateField('website')"
            />
          </div>
          <p class="help is-danger">{{ fields.website.message }}</p>
        </div>
      </div>
    </div>

    <hr />
    <div class="columns mb-2">
      <div class="column">
        <div class="buttons">
          <b-button type="is-primary" @click="performPatch">Save</b-button>
          <cancel-btn></cancel-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CancelBtn from "../small/CancelBtn.vue";

export default {
  components: {
    CancelBtn,
  },
  data() {
    return {
      readOnly: false,
      employer: {
        id: "",
        reg_no: "",
        slug: "",
        name: "",
        email: "",
        address: "",
        website: "",
        phone: "",
        intro: "",
        logo: null,
        is_verified: false,
        location: "",
        sector: null,
      },
      file: {},
      locationOptions: [],
      orgSizeOptions: [],
      sectorOptions: [],
      fields: {
        name: {
          label: "Name",
          class: "",
          error: false,
          message:
            "This field cannot be edited here. Please contact administrator",
        },
        reg_no: {
          label: "Registration No",
          class: "",
          error: false,
          message: "",
        },
        email: {
          label: "Email",
          class: "",
          error: false,
          message: "",
        },
        phone: {
          label: "Phone",
          class: "",
          error: false,
          message: "",
        },
        address: {
          label: "Address",
          class: "",
          error: false,
          message: "",
        },
        website: {
          label: "Website",
          class: "",
          error: false,
          message: "",
        },
        intro: {
          label: "Introduction",
          class: "",
          error: false,
          message: "",
        },
        logo: {
          label: "Logo",
          class: "",
          error: false,
          message:
            "Please choose a square image with a minimum of 500px x 500px",
        },
        location: {
          label: "Location",
          class: "",
          error: false,
          message: "",
        },
        sector: {
          label: "Sector",
          class: "",
          error: false,
          message: "",
        },
        org_size: {
          label: "Organization Size",
          class: "",
          error: false,
          message: "",
        },
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    getEmployer() {
      const emp = this.$store.getters["employers/getEmployer"];
      const clone = { ...emp };
      this.employer.id = clone.id;
      this.employer.reg_no = clone.reg_no;
      this.employer.slug = clone.slug;
      this.employer.name = clone.name;
      this.employer.email = clone.email;
      this.employer.address = clone.address;
      this.employer.website = clone.website;
      this.employer.phone = clone.phone;
      this.employer.intro = clone.intro;
      this.employer.logo = clone.logo;
      this.employer.is_verified = clone.is_verified;
      if (clone.location) {
        this.employer.location = clone.location.id;
      } else {
        this.employer.location = null;
      }
      if (clone.sector) {
        this.employer.sector = clone.sector.id;
      } else {
        this.employer.sector = null;
      }
      if (clone.org_size) {
        this.employer.org_size = clone.org_size.id;
      } else {
        this.employer.org_size = null;
      }
    },
    async fetchLocations() {
      const res = await axios.get("/api/locations/");
      res.data.forEach((loc) => {
        this.locationOptions.push({
          text: loc.name,
          value: loc.id,
        });
      });
    },
    async fetchSectors() {
      const response = await axios.get("/api/sectors/");
      response.data.forEach((sector) => {
        this.sectorOptions.push({
          text: sector.name,
          value: sector.id,
        });
      });
    },
    async fetchOrgsizes() {
      const response = await axios.get("/api/orgsizes/");
      response.data.forEach((orgsize) => {
        this.orgSizeOptions.push({
          text: orgsize.name,
          value: orgsize.id,
          order: orgsize.order,
        });
      });
      // sort org sizes based on order
      this.orgSizeOptions.sort((a, b) => {
        return a.order - b.order;
      });
    },
    validateField(field) {
      if (field === "name") {
        if (this.employer.name.length < 3) {
          this.fields.name.class = "is-danger";
          this.fields.name.error = true;
          this.fields.name.message =
            "Name is required. Must be at least 3 characters.";
        } else {
          this.fields.name.class = "";
          this.fields.name.error = false;
          this.fields.name.message = "";
        }
      } else if (field === "reg_no") {
        if (this.employer.reg_no.length < 1) {
          this.fields.reg_no.class = "is-danger";
          this.fields.reg_no.error = true;
          this.fields.reg_no.message = "Registration number is required.";
        } else {
          this.fields.reg_no.class = "";
          this.fields.reg_no.error = false;
          this.fields.reg_no.message = "";
        }
      } else if (field === "email") {
        if (this.employer.email.length < 1) {
          this.fields.email.class = "is-danger";
          this.fields.email.error = true;
          this.fields.email.message = "Email is required.";
        } else {
          this.fields.email.class = "";
          this.fields.email.error = false;
          this.fields.email.message = "";
        }
      } else if (field === "phone") {
        if (this.employer.phone.length < 1) {
          this.fields.phone.class = "is-danger";
          this.fields.phone.error = true;
          this.fields.phone.message = "Phone number is required.";
        } else {
          this.fields.phone.class = "";
          this.fields.phone.error = false;
          this.fields.phone.message = "";
        }
      } else if (field === "address") {
        if (!this.employer.address || this.employer.address.length < 3) {
          this.fields.address.class = "is-danger";
          this.fields.address.error = true;
          this.fields.address.message =
            "Address is required and cant be that short.";
        } else {
          this.fields.address.class = "";
          this.fields.address.error = false;
          this.fields.address.message = "";
        }
      } else if (field === "website") {
        if (this.employer.website) {
          if (this.employer.website.length < 3) {
            this.fields.website.class = "is-danger";
            this.fields.website.error = true;
            this.fields.website.message = "Website can't be that short.";
          } else {
            this.fields.website.class = "";
            this.fields.website.error = false;
            this.fields.website.message = "";
          }
        } else {
          this.fields.website.class = "";
          this.fields.website.error = false;
          this.fields.website.message = "";
        }
      } else if (field === "intro") {
        if (!this.employer.intro || this.employer.intro.length < 25) {
          this.fields.intro.class = "is-danger";
          this.fields.intro.error = true;
          this.fields.intro.message =
            "Introduction is required. Must be at least 25 characters";
        } else {
          this.fields.intro.class = "";
          this.fields.intro.error = false;
          this.fields.intro.message = "";
        }
      } else if (field === "logo") {
        if (!this.employer.logo && !this.file.name) {
          this.fields.logo.class = "is-danger";
          this.fields.logo.error = true;
          this.fields.logo.message = "You must upload a logo.";
        } else {
          this.fields.logo.class = "";
          this.fields.logo.error = false;
          this.fields.logo.message = "";
        }
      } else if (field === "location") {
        if (!this.employer.location) {
          this.fields.location.class = "is-danger";
          this.fields.location.error = true;
          this.fields.location.message = "Location is required";
        } else {
          this.fields.location.class = "";
          this.fields.location.error = false;
          this.fields.location.message = "";
        }
      } else if (field === "sector") {
        if (!this.employer.sector) {
          this.fields.sector.class = "is-danger";
          this.fields.sector.error = true;
          this.fields.sector.message = "Sector is required";
        } else {
          this.fields.sector.class = "";
          this.fields.sector.error = false;
          this.fields.sector.message = "";
        }
      } else if (field === "org_size") {
        if (!this.employer.org_size) {
          this.fields.org_size.class = "is-danger";
          this.fields.org_size.error = true;
          this.fields.org_size.message = "Organization Size is required";
        } else {
          this.fields.org_size.class = "";
          this.fields.org_size.error = false;
          this.fields.org_size.message = "";
        }
      }
    },
    formIsValid() {
      let isValid = true;
      for (let field in this.fields) {
        if (this.fields[field].error) {
          isValid = false;
        }
      }
      return isValid;
    },
    async submitForm() {
      //set loading
      this.$store.dispatch("setLoading", true);

      //create formdata
      const formData = new FormData();
      formData.append("name", this.employer.name);
      formData.append("reg_no", this.employer.reg_no);
      formData.append("email", this.employer.email);
      formData.append("address", this.employer.address);
      formData.append("website", this.employer.website);
      formData.append("phone", this.employer.phone);
      formData.append("intro", this.employer.intro);
      formData.append("location", this.employer.location);
      formData.append("sector", this.employer.sector);
      formData.append("org_size", this.employer.org_size);

      //if file is selected
      if (this.file.name) {
        formData.append("logo", this.file);
      }

      //make api call
      let response = await axios.patch(
        `/api/employers/${this.employer.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //return response
      return response;
    },
    performPatch() {
      //validate form
      for (let field in this.fields) {
        this.validateField(field);
      }
      //if form is valid
      if (this.formIsValid()) {
        this.submitForm()
          .then((response) => {
            this.$store
              .dispatch("employers/updateEmployer", response.data.id)
              .then(() => {
                this.$store.dispatch("setLoading", false);
                this.$router.push({ name: "EmployerProfile" });
              });
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            let err = error.response.data;
            let i;
            for (i in err) {
              this.fields[i].class = "is-danger";
              this.fields[i].error = true;
              this.fields[i].message = err[i][0];
              this.$buefy.toast.open({
                message: err[i][0],
                type: "is-danger",
                duration: 6000,
                queue: true,
              });
            }
            this.$store.dispatch("setLoading", false);
          });
      } else {
        window.scrollTo(0, 0);
        this.$store.dispatch("setLoading", false);
        this.$buefy.toast.open({
          message: "Form contains errors. Please correct and try again.",
          type: "is-danger",
          queue: false,
          duration: 3000,
        });
      }
    },
  },
  mounted() {
    //
  },
  created() {
    this.$store.dispatch("setLoading", true);
    this.getEmployer();
    this.fetchLocations().then(() => {
      this.fetchSectors().then(() => {
        this.fetchOrgsizes().then(() => {
          this.$store.dispatch("setLoading", false);
        });
      });
    });
  },
};
</script>

<style></style>
